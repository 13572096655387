<template>
  <div class="page">
    <section>
      <span class="title">身份认证</span>
      <!-- 一屏 -->
      <div class="box-one">
        <!-- 左侧 -->
        <div class="one-left">
          <!-- 姓名 -->
          <div class="information">
            <span class="name">姓名</span>
            <span class="star">*</span>
            <el-input
              autocomplete="off"
              v-model="form.name"
              placeholder="请输入"
              :disabled="disable"
              @blur="toastInfo($event, '请输入姓名')"
            ></el-input>
          </div>
          <!-- 身份证 -->
          <div class="information">
            <span class="name">身份证号</span>
            <span class="star">*</span>
            <el-input
              autocomplete="off"
              v-model="form.code"
              :disabled="disable"
              placeholder="请输入"
              @blur="toastInfo($event, '请输入身份证号')"
            ></el-input>
          </div>

          <!-- 执业所在地 -->
          <div class="information">
            <span class="name">执业所在地</span>
            <span class="star">*</span>
            <el-cascader
              ref="chengCascader"
              :options="list5"
              v-model="pcArea"
              :disabled="disable1"
              separator=" -> "
              :props="listPorpsArea"
              @change="getCategoryArea"
            ></el-cascader>
          </div>

          <!-- 执业单位 -->
          <div class="information">
            <span class="name">执业单位</span>
            <span class="star">*</span>
            <el-autocomplete
              class="inline-input"
              v-model="form.company"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect"
              :disabled="disable1"
            >
            </el-autocomplete>
          </div>

          <!-- 执业单位等级 -->
          <div class="information">
            <span class="name">执业单位等级</span>
            <span class="star">*</span>
            <el-select
              :disabled="disable1"
              v-model="form.level_name"
              :popper-append-to-body="false"
              placeholder="请选择"
              @change="selectLevel"
            >
              <el-option
                v-for="item in levelList"
                :key="item.id"
                :value="item.level"
                :label="item.level_name"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 所在科室 -->
          <div class="information">
            <span class="name">所在科室</span>
            <span class="star">*</span>
            <el-input
              autocomplete="off"
              v-if="chengSInput"
              v-model="form.department_name"
              :disabled="disable1"
              placeholder="请选择"
              @focus="chengSFun"
            ></el-input>
            <el-cascader
              v-if="chengSCascader"
              ref="chengCascaderChengTwo"
              :options="list5Two"
              v-model="pcAreaTwo"
              :disabled="disable1"
              separator=" -> "
              :props="listPorpsAreaTwo"
              @change="getCategoryAreaTwo"
            ></el-cascader>
          </div>

          <!-- 职称 -->
          <div class="information">
            <span class="name">职称</span>
            <span class="star">*</span>
            <el-select
              :popper-append-to-body="false"
              v-model="form.title_id"
              :disabled="disable1"
              placeholder="请选择"
              @change="selectTitle"
            >
              <el-option
                v-for="item in list2"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- 右侧 -->
        <div class="one-right">
          <!-- 照片 -->
          <div class="photo">
            <div class="photo-name">
              照片
              <span class="photo-star">*</span>
            </div>
            <div class="upload">
              <el-upload
                :action="$http.baseURL + '/dkt/other/upload'"
                list-type="picture-card"
                ref="upload1"
                multiple
                name="files"
                :disabled="disState1"
                :class="{ hide: disState1 }"
                :before-upload="progressImg"
                :on-success="upSucc"
                :limit="1"
                :file-list="fileImg1"
              >
                <i class="iconfont iconicon-test1"></i>
                <span>上传照片</span>
                <div
                  slot="file"
                  slot-scope="{ file }"
                  style="width: 100%; height: 100%"
                >
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="form.photo"
                    alt=""
                    v-if="form.photo"
                    style="background-size: 100%"
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!disable1"
                      class="el-upload-list__item-delete"
                      @click="!disable1 && handleRemove('upload1', file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </div>
            <span class="prompt">上传二寸照片</span>
          </div>

          <!-- 医师资格证书编号 -->
          <div class="information" style="margin: 30px 0">
            <span class="name">医师资格证书编号</span>
            <span class="star">*</span>
            <el-input
              autocomplete="off"
              v-model="form.doctor_code"
              :disabled="disable"
              placeholder="请输入"
              @blur="toastInfo($event, '请输入医师资格证书编号')"
            ></el-input>
          </div>

          <!-- 医师执业证书编号 -->
          <div class="information">
            <span class="name">医师执业证书编号</span>
            <span class="star">*</span>
            <el-input
              autocomplete="off"
              v-model="form.practice_code"
              :disabled="disable"
              placeholder="请输入"
              @blur="toastInfo($event, '请输入医师执业证书编号')"
            ></el-input>
          </div>
        </div>
      </div>

      <!-- 二屏 -->
      <div class="box-oneCheng">
        <!-- 副标 -->
        <div class="twoOne">二选一上传证书</div>

        <!-- 上传医师资格证书 -->
        <div class="photo">
          <div class="photo-name">
            医师资格证书
            <span class="photo-star">*</span>
          </div>
          <div class="smallTitle">1.上传带证书编码的那页</div>
          <div class="smallTitleTwo">2.有职称的那页</div>
          <div class="upload">
            <el-image
              v-for="item in qualificationsList"
              :key="item.src"
              style="height: 135px; margin-right: 10px; width: 200px"
              :src="item.src"
              :preview-src-list="item.previewSrcList"
            >
            </el-image>
            <el-upload
              :action="$http.baseURL + '/dkt/other/upload'"
              list-type="picture-card"
              ref="upload2"
              :multiple="true"
              name="files"
              :disabled="disState2"
              :class="{ hide: disState2 }"
              :before-upload="progressImg2"
              :on-change="handleVideoSuccessTwo"
              :on-success="upSucc1"
              :file-list="fileImg2"
              :limit="2"
              :on-exceed="handleExceedTwo"
            >
              <i class="iconfont iconicon-test1"></i>
              <span>上传证书</span>
              <div
                slot="file"
                slot-scope="{ file }"
                style="width: 100%; height: 100%"
              >
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                  v-if="file.url"
                  style="background-size: 100%"
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disable"
                    class="el-upload-list__item-delete"
                    @click="!disable && handleRemoveGTwo(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </div>
          <!-- <span class="prompt">上传二寸照片</span> -->
        </div>

        <!-- 上传医师执业证书 -->
        <div class="photo">
          <div class="photo-name">
            医师执业证书
            <span class="photo-star">*</span>
          </div>

          <div class="smallTitle">1.上传带证书编码的那页</div>
          <div class="smallTitleTwo">2.有职称的那页</div>

          <div class="upload">
            <el-image
              v-for="(item, index) in practiceList"
              :key="item.src"
              style="height: 135px; margin-right: 10px; width: 200px"
              :src="item.src"
              :preview-src-list="item.previewSrcList"
              :class="`preview${index}`"
            >
            </el-image>
            <el-upload
              :action="$http.baseURL + '/dkt/other/upload'"
              list-type="picture-card"
              ref="upload3"
              multiple
              name="files"
              :disabled="disState3"
              :class="{ hide: disState3 }"
              :on-change="handleVideoSuccess"
              :before-upload="progressImg3"
              :on-success="upSucc2"
              :file-list="fileImg3"
              :on-exceed="handleExceed"
              :limit="2"
            >
              <i class="iconfont iconicon-test1"></i>
              <span>上传证书</span>

              <div
                slot="file"
                v-if="file.url != ''"
                slot-scope="{ file }"
                style="width: 100%; height: 100%"
              >
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                  v-if="file.url != ''"
                  style="background-size: 100%"
                />
                <span
                  class="el-upload-list__item-actions"
                  v-if="file.url != ''"
                >
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>

                  <span
                    class="el-upload-list__item-delete"
                    v-if="!disable"
                    @click="handleRemoveG(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </div>
        </div>
      </div>

      <span @click="selectInfo" class="determine">确定</span>

      <!-- 预览大图对话框 -->
      <el-dialog :visible.sync="dialogVisible">
        <img :src="dialogImageUrl" alt="" />
      </el-dialog>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      chengSInput: true, // 所在科室input是否显示
      chengSCascader: false, // 所在科室级联选择器是否显示
      hospital: [],
      disable: false, // 认证之后不可修改内容
      disable1: false, // 认证之后可修改内容
      dialogImageUrl: "", // 大图预览图片路径
      dialogVisible: false, // 预览大图对话框
      disState1: false, //上传照片功能是否禁用
      disState2: false, // 上传医师资格证书是否禁用
      disState3: false, // 上传医师执业证书是否禁用
      fileImg1: [], // 照片数组
      fileImg2: [], // 医师资格证书数组
      fileImg3: [], // 医师执业证书数组
      area: [], // 执业所在地
      areaTwo: "",
      pcArea: [], // 执业所在地选中项绑定值
      pcAreaTwo: [], // 所在科室选中项绑定值
      form: {
        id: "",
        name: "", //姓名
        photo: "", //照片
        code: "", //身份证号
        doctor_code: "", //医师资格证书编号
        doctor_photo: "", //证书照片
        company: "", //执业单位
        department_name: "", //科室名称-第二级
        department_code: "", //科室id-第二级
        province: "", // 身份
        city: "", // 城市
        area: "", //区域
        title_name: "", //职称
        title_id: "", //职称
        job_name: "", //职务
        job_id: "", //职务
        practice_code: "", //医师执业证书编号
        practice_photo: [], //医师执业证书
      }, // 表单
      levelList: [], // 执业单位等级
      list2: [], // 职称列表
      list4: [], // 科室列表
      list5: [], // 省市区
      list5Two: [], // 所在科室
      listPorpsArea: {
        value: "code",
        label: "name",
        children: "children",
      }, // 执业所在地的配置选项
      listPorpsAreaTwo: {
        value: "id",
        label: "name",
        children: "child",
      }, // 所在科室的配置选项
      userStatus: "", // 用户状态
      qualificationsList: [
        {
          src: require("@image/topOneC.png"),
          previewSrcList: [require("@image/topOneC.png")],
        },
        {
          src: require("@image/topTwoC.png"),
          previewSrcList: [require("@image/topTwoC.png")],
        },
      ], // 资格证书图片预览
      practiceList: [
        {
          src: require("@image/bottonmOneS.png"),
          previewSrcList: [require("@image/bottonmOneS.png")],
        },
        {
          src: require("@image/bottonmTwoS.png"),
          previewSrcList: [require("@image/bottonmTwoS.png")],
        },
        {
          src: require("@image/bottonmThreeS.png"),
          previewSrcList: [require("@image/bottonmThreeS.png")],
        },
      ], // 执业证书图片预览
    };
  },
  
  created() {
    this.getLists5();
    this.getLists2();
    this.getLists4();
    this.get_department();
    this.form.name = this.userInfo.nickname;
    this.form.code = this.userInfo.id_card;
    this.form.company = this.userInfo.unit;
    if (this.userInfo.company) {
      this.getDocterInfo();
    }
  },

  mounted() {
    this.getDocterLevel();
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },

  methods: {
    // 获取职业等级
    async getDocterLevel() {
      let res = await this.$newsApi.getDocterLevel({});
      if (res.code == 200) {
        this.levelList = res.data;
        this.form.level_id = "";
      } else {
        this.$message.error(res.message);
      }
    },

    // 所在科室聚焦事件
    chengSFun() {
      this.chengSCascader = true;
      this.chengSInput = false;
    },

    // 带输入建议的input,返回输入建议的方法
    async querySearch(queryString, cb) {
      let res = await this.$newsApi.hospital_list({
        province_code: this.pcArea[0],
        city_code: this.pcArea[1],
        district_code: this.pcArea[2],
        hospital_name: this.form.company,
      });
      if (res.code === 200) {
        this.hospital = Array.from(res.data).map((terminal) => {
          if (terminal.hname) {
            terminal.value = terminal.hname;
            terminal.name = terminal.id;
          }
          return terminal;
        });
      }
      var results = queryString
        ? this.hospital.filter(this.createFilter(queryString))
        : this.hospital;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.hname.toLowerCase().indexOf(queryString.toLowerCase()) !==
          -1
        );
      };
    },

    handleSelect() {},

    // 获取科室
    async get_department() {
      let res = await this.$newsApi.get_department({});
      if (res.code === 200) {
        this.list5Two = res.data;
      }
    },

    // 输入框失去焦点时触发(为空时设置placeholder)
    toastInfo(event, el) {
      if (!event.target.value) {
        this.$message({
          message: el,
          type: "info",
        });
      }
    },

    // 删除照片
    handleRemove(ref, file) {
      this.$refs[ref].handleRemove(file);
      // 删除照片
      if (ref === "upload1") {
        this.form.photo = "";
        this.fileImg1 = [];
        setTimeout(() => {
          this.disState1 = false;
        }, 500);
      } else if (ref === "upload2") {
        // 删除医师资格证
        this.form.doctor_photo = "";
        this.fileImg2 = [];
        setTimeout(() => {
          this.disState2 = false;
        }, 500);
      } else if (ref === "upload3") {
        // 删除医师执业证
        this.form.practice_photo = [];
        this.fileImg3 = [];
        setTimeout(() => {
          this.disState3 = false;
        }, 500);
      }
    },

    // 删除医师资格证书
    handleRemoveGTwo(file) {
      let index = this.fileImg2.indexOf(file);
      this.fileImg2.splice(index, 1);
      this.form.doctor_photo = JSON.stringify(
        this.fileImg2.map((it) => (it.response ? it.response.data : it.url))
      );
      if (this.fileImg2.length == 0) {
        setTimeout(() => {
          this.disState2 = false;
        }, 500);
      }
      if (this.fileImg2.length == 1) {
        setTimeout(() => {
          this.disState2 = false;
        }, 500);
      }
      if (this.fileImg2.length == 2) {
        this.disState2 = true;
      }
    },

    // 删除医师执业证书
    handleRemoveG(file) {
      let index = this.fileImg3.indexOf(file);
      this.fileImg3.splice(index, 1);
      this.form.practice_photo = JSON.stringify(
        this.fileImg3.map((it) => (it.response ? it.response.data : it.url))
      );
      if (this.fileImg3.length == 0) {
        setTimeout(() => {
          this.disState3 = false;
        }, 500);
      }
      if (this.fileImg3.length == 1) {
        setTimeout(() => {
          this.disState3 = false;
        }, 500);
      }
      if (this.fileImg3.length == 2) {
        this.disState3 = true;
      }
    },

    // 图片预览大图点击事件
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleDownload(file) {
      file;
    },
    // 1.职务  2.职称 3.专业
    async getLists2() {
      let res = await this.$newsApi.getDocterPosition({
        type: 2,
      });
      if (res.code === 200) {
        this.list2 = res.data;
      }
    },

    //获取认证科室列表
    async getLists4() {
      let res = await this.$newsApi.getDocterDepartment({});
      if (res.code === 200) {
        this.list4 = res.data;
      }
    },

    //获取省市区
    async getLists5() {
      let res = await this.$newsApi.areaNew({});
      if (res.code === 200) {
        this.list5 = res.data;
      }
    },

    //获取职称
    selectTitle(val) {
      try {
        this.list2.forEach((item, index) => {
          if (item.id === val) {
            this.form.title_name = item.name;
            throw "succ";
          }
        });
      } catch (error) {
        error;
      }
    },

    // 获取等级
    selectLevel(val) {
      this.form.level = val;
    },

    // 获取所在科室
    getCategoryAreaTwo(val) {
      this.areaTwo = val;
      let a = this.areaTwo.length;
      this.list5Two.forEach((item, index) => {
        if (a > 0) {
          //取一级内容
          if (item.id === this.areaTwo[0]) {
            if (a > 1) {
              //取二级内容
              item.child.forEach((ite, ind) => {
                if (ite.id === this.areaTwo[1]) {
                  this.form.department_code = ite.id;
                  this.form.department_name = ite.name;
                }
              });
            }
          }
        }
      });
    },

    //获取省市区
    getCategoryArea(val) {
      this.area = val;
      let a = this.area.length;
      this.list5.forEach((item, index) => {
        if (a > 0) {
          //取一级内容
          if (item.code === this.area[0]) {
            this.form.province = item.code;
            if (a > 1) {
              //取二级内容
              item.children.forEach((ite, ind) => {
                if (ite.code === this.area[1]) {
                  this.form.city = ite.code;
                  if (a > 2) {
                    //取三级内容
                    ite.children.forEach((ite2, ind2) => {
                      if (ite2.code === this.area[2]) {
                        this.form.area = ite2.code;
                      }
                    });
                  }
                }
              });
            }
          }
        }
      });
    },

    // 提交
    async selectInfo() {
      if (!this.form.name) {
        this.$message({
          message: "请输入姓名",
          type: "info",
        });
        return;
      }
      if (!this.form.code) {
        this.$message({
          message: "请输入身份证号",
          type: "info",
        });
        return;
      }

      if (!this.form.province) {
        this.$message({
          message: "请选择省市区",
          type: "info",
        });
        return;
      }
      if (!this.form.city) {
        this.$message({
          message: "请选择省市区",
          type: "info",
        });
        return;
      }
      if (!this.form.area) {
        this.$message({
          message: "请选择省市区",
          type: "info",
        });
        return;
      }

      if (!this.form.doctor_code) {
        this.$message({
          message: "请输入医师资格证书编号",
          type: "info",
        });
        return;
      }

      if (!this.form.photo) {
        this.$message({
          message: "请上传照片",
          type: "info",
        });
        return;
      }
      if (
        (!this.form.practice_photo ||
          this.form.practice_photo == [] ||
          this.form.practice_photo == "[]") &&
        (!this.form.doctor_photo ||
          this.form.doctor_photo == [] ||
          this.form.doctor_photo == "[]")
      ) {
        this.$message({
          message: "医师执业证书图片和上传医师资格证书照片二选一",
          type: "info",
        });

        return;
      }
      if (!this.form.company) {
        this.$message({
          message: "请输入执业单位",
          type: "info",
        });
        return;
      }

      if (!this.form.level) {
        this.$message({
          message: "请选择执业单位等级",
          type: "info",
        });
      }

      if (!this.form.title_id) {
        this.$message({
          message: "请选择职称",
          type: "info",
        });
        return;
      }

      if (!this.form.title_name) {
        this.$message({
          message: "请选择职称",
          type: "info",
        });
        return;
      }

      if (!this.form.practice_code) {
        this.$message({
          message: "请输入医师执业证书编号",
          type: "info",
        });
        return;
      }

      if (!this.form.department_name) {
        this.$message({
          message: "请输入所在科室",
          type: "info",
        });
        return;
      }
      delete this.form.province_name;
      delete this.form.city_name;
      delete this.form.area_name;

      // 第一次认证
      if (!this.form.id) {
        let res = await this.$newsApi.addCertification(this.form);
        if (res.code === 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/homepage");
          }, 500);
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
          this.$loading.hide();
        }
      } else {
        let res = await this.$newsApi.updateCertification(this.form);
        if (res.code === 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/homepage");
          }, 500);
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
          this.$loading.hide();
        }
      }
    },

    // 上传照片前的钩子
    progressImg(file) {
      if (
        file.type.split("/")[0] != "image" ||
        file.type.split("/")[1] == "webp"
      ) {
        this.$message({
          message: "图片格式不正确",
          type: "error",
        });
        return false;
      }
      this.disState1 = true;
    },

    // 上传医师资格证书前的钩子
    progressImg2(file) {
      if (
        file.type.split("/")[0] != "image" ||
        file.type.split("/")[1] == "webp"
      ) {
        this.$message({
          message: "图片格式不正确",
          type: "error",
        });
        return false;
      }
      //   this.loading = true
      // this.disState2 = true;
    },

    // 上传医师执业证书前的钩子
    progressImg3(file) {
      if (
        file.type.split("/")[0] != "image" ||
        file.type.split("/")[1] == "webp"
      ) {
        this.$message({
          message: "图片格式不正确",
          type: "error",
        });
        return false;
      }
    },

    // 医师执业证书文件超出个数限制时的钩子
    handleExceed() {
      this.$message({
        message: "最多上传两张图片",
        type: "error",
      });
    },

    // 医师资格证书文件超出个数限制时的钩子
    handleExceedTwo() {
      this.$message({
        message: "最多上传两张图片",
        type: "error",
      });
    },

    // 医师执业证书文件状态改变时的钩子
    handleVideoSuccess(response, file, fileList) {
      if (file.length == 2) {
        this.disState3 = true;
      }
    },

    // 医师资格证书文件状态改变时的钩子
    handleVideoSuccessTwo(response, file, fileList) {
      if (file.length == 2) {
        this.disState2 = true;
      }
    },

    //获取照片片
    upSucc(response, file, fileList) {
      if (response.code === 200) {
        this.form.photo = response.data;
      }
    },

    //医生证书
    upSucc1(response, file, fileList) {
      if (response.code === 200) {
        this.fileImg2 = fileList;
        this.form.doctor_photo = JSON.stringify(
          this.fileImg2.map((it) => (it.response ? it.response.data : it.url))
        );

        if (this.fileImg2.length == 2) {
          this.disState2 = true;
        }
      }
    },

    //执业证书
    upSucc2(response, file, fileList) {
      if (response.code === 200) {
        this.fileImg3 = fileList;
        this.form.practice_photo = JSON.stringify(
          this.fileImg3.map((it) => (it.response ? it.response.data : it.url))
        );

        if (this.fileImg3.length == 2) {
          this.disState3 = true;
        }
      }
    },

    //获取身份认证信息
    async getDocterInfo() {
      this.xin.isLoadding(true);
      let res = await this.$newsApi.infoCertification();
      if (res.code === 200) {
        this.pcArea = [res.data.province, res.data.city, res.data.area];
        this.getLists5();
        this.form = res.data;
        this.userStatus = this.form.status;
        this.disable = this.form.status == 2 ? false : true;
        this.disable1 =
          this.form.status == 1 || this.form.status == 2 ? false : true;
        this.form.status == 2
          ? this.form.code
          : (this.form.code = this.form.code.replace(
              /(\w{3})\w*(\w{4})/,
              "$1******$2"
            ));
        this.form.status == 2
          ? this.form.code
          : (this.form.doctor_code = this.form.doctor_code.replace(
              /(\w{2})\w*(\w{2})/,
              "$1******$2"
            ));
        this.form.status == 2
          ? this.form.code
          : (this.form.practice_code = this.form.practice_code.replace(
              /(\w{2})\w*(\w{2})/,
              "$1******$2"
            ));

        this.fileImg1 = [
          {
            url: res.data.photo,
          },
        ];

        if (this.form.doctor_photo) {
          const that = this;
          let photoStringTwo = res.data.doctor_photo;
          let array = JSON.parse(photoStringTwo);
          if (array.length) {
            array.forEach(function (it) {
              that.fileImg2.push({
                url: it,
              });
            });
          }
        }

        const that = this;
        if (this.form.practice_photo) {
          let photoString = res.data.practice_photo;
          let array = JSON.parse(photoString);
          if (array.length) {
            array.forEach(function (it) {
              that.fileImg3.push({
                url: it,
              });
            });
          }
        }
        if (JSON.parse(this.form.practice_photo).length == 2) {
          this.disState3 = true;
        }
        this.disState1 = true;
        if (
          this.form.status != 2 &&
          this.form.status != 9 &&
          this.form.status != 1
        ) {
          this.disState3 = true;
          this.disState2 = true;
        } else {
          this.disState3 = false;
          this.disState2 = false;
        }
        if (JSON.parse(this.form.practice_photo).length == 2) {
          this.disState3 = true;
        }
        if (JSON.parse(this.form.doctor_photo).length == 2) {
          this.disState2 = true;
        }
        this.xin.isLoadding(false);
        if (
          this.form.status != 9 &&
          this.form.status != 2 &&
          this.form.status != 1
        ) {
          this.qualificationsList = [];
          this.practiceList = [];
        }
      }
    },
  },
};
</script>


<style>
.hide .el-upload--picture-card {
  display: none !important;
}

.el-upload-list--picture-card .el-upload-list__item {
  height: 148px;
  width: 148px;
  margin-top: 5px;
  position: relative;
}

.el-upload-list--picture-card .el-upload-list__item img {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

<style lang="scss" scoped>
@import "@/assets/css/themeColor.scss";

::v-deep {
  .el-input {
    width: 240px;
    height: 40px;
    background: #f2f2f2;
    border-radius: 0;
    margin-left: 20px;
  }

  .col {
    color: #ffffff;
  }

  .el-upload {
    width: 110px;
    height: 110px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8f8f8f;

    &:hover {
      color: $--themeColor;
      border-color: $--themeColor;

      .iconfont {
        color: $--themeColor;
      }
    }

    > .iconfont {
      line-height: 30px;
      height: max-content;
    }

    > span {
      height: max-content;
      line-height: 24px;
    }
  }

  .el-upload--picture-card {
    width: 146px;
    height: 146px;
    margin-top: 5px;
    &:hover {
      border-color: $--themeColor;
    }

    background-color: transparent;
  }
}

.page {
  background: #f2f2f2;

  > section {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 22px;
    }

    > .box-one {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 1200px;
      background: #ffffff;
      padding: 40px 130px;
      box-sizing: border-box;
      margin-bottom: 20px;

      .photo-star {
        color: #e60012;
        margin-left: 2px;
      }

      .star {
        color: #e60012;
        margin-left: 2px;
      }

      &:last-child .one-left {
        margin-right: 0;
        // margin-left: 12px;
      }

      > .one-left {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        // margin-right: 130px;

        > .photo {
          display: flex;
          flex-direction: row;
          font-size: 18px;
          color: #333333;

          > .photo-name {
            margin-left: 50px;
            text-align: right;
          }

          > .upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 110px;
            height: 110px;
            color: #8f8f8f;
            font-size: 16px;
            margin-left: 19px;

            > .photo-icon {
              width: 36px;
              height: 36px;
              object-fit: cover;
              margin: 18px 0 15px 0;
            }
          }

          > .prompt {
            font-size: 12px;
            color: #e60012;
            margin-left: 17px;
            margin-top: auto;
          }
        }

        > .information {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 30px;

          > .name {
            width: 159px;
            text-align: right;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      > .one-right {
        display: flex;
        flex-direction: column;

        > .photo {
          display: flex;
          flex-direction: row;
          font-size: 18px;
          color: #333333;
          margin-bottom: 30px;

          &:last-child {
            margin-top: 100px;
          }

          > .photo-name {
            width: 123px;
            text-align: right;

            > .photo-star {
              color: #e60012;
            }
          }

          > .upload {
            width: 148px;
            height: 148px;
            color: #8f8f8f;
            font-size: 16px;
            margin-left: 19px;

            > .photo-icon {
              width: 36px;
              height: 36px;
              object-fit: cover;
              margin: 18px 0 15px 0;
            }
          }

          > .prompt {
            font-size: 12px;
            color: #e60012;
            margin-left: 17px;
            margin-top: auto;
          }
        }
      }
    }

    > .box-three {
      display: flex;
      flex-direction: column;
      width: 1200px;
      padding: 30px 130px;
      box-sizing: border-box;
      font-size: 18px;
      background: #ffffff;

      > .contribution {
        font-size: 12px;
        margin-bottom: 10px;
      }

      > .specificContribution {
        display: flex;
        flex-direction: row;

        > .theAmount {
          display: flex;
          flex-direction: column;
          color: #333333;
          margin-right: 176px;

          &:last-child {
            margin-right: 0;
          }

          > .left-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;

            > .add {
              &:last-child {
                margin-left: 200px;
              }

              width: 24px;
              height: 24px;
              object-fit: cover;
              margin-left: 120px;
            }
          }

          > .contributeContent {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }

            > .contribute-name {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 240px;
              height: 40px;
              // background: #fafafa;
              color: #8f8f8f;
              // padding-left: 20px;
              box-sizing: border-box;
              margin-right: 5px;

              > .el-input {
                margin-left: 0;
              }
            }

            > .consultation {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 100px;
              height: 40px;
              // background: #fafafa;
              color: #8f8f8f;
              margin-right: 10px;
            }

            > .delete {
              width: 16px;
              height: 16px;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }
      }
    }

    > .determine {
      cursor: pointer;
      font-size: 18px;
      color: #ffffff;
      width: 170px;
      height: 50px;
      background: $--themeColor;
      border: 1px solid $--themeColor;
      border-radius: 26px;
      text-align: center;
      line-height: 50px;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}

.prompt {
  font-size: 12px;
  color: #e60012;
  margin-left: 17px;
  margin-top: auto;
}

/deep/ .el-upload-list--picture-card {
  display: flex;
  // flex-direction: row;
  // justify-content: center;
  align-items: center;
}

.uploadDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin-left:170px;
}

.uploadDiv img {
  width: 100px;
  height: 100px;
}

.uploadDiv /deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}

.uploadDiv /deep/.el-upload .el-upload--picture-card {
  width: 146px;
  height: 146px;
}

.twoOne {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #8f8f8f;
  opacity: 1;
  margin-bottom: 14px;
}

.box-oneCheng {
  width: 1200px;
  background: #ffffff;
  padding-left: 159px;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 30px;

  .photo {
    font-size: 18px;
    color: #333333;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;

    > .photo-name {
      font-size: 16px;

      > .photo-star {
        color: #e60012;
        margin-left: 3px;
      }
    }

    > .upload {
      display: flex;
      align-items: center;
      width: 95%;
      height: 170px;
      color: #8f8f8f;
      font-size: 16px;
      margin-left: 10px;
      flex-direction: row;
      div {
        display: flex;
        flex-direction: row;
      }
      > .el-upload--picture-card {
        display: inline-block;
      }

      > .photo-icon {
        width: 36px;
        height: 36px;
        object-fit: cover;
        margin: 18px 0 15px 0;
      }
    }

    > .prompt {
      font-size: 12px;
      color: #e60012;
      margin-left: 17px;
      margin-top: auto;
    }
  }

  > .information {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    > .name {
      width: 159px;
      text-align: right;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > .star {
      color: #e60012;
      margin-left: 2px;
    }
  }

  /deep/ .upload {
    .el-image {
      position: relative;

      &::after {
        content: "示例";
        position: absolute;
        color: #fff;
        background: #281e4a;
        width: 100%;
        height: 30px;
        bottom: 0;
        left: 0;
        text-align: center;
        line-height: 30px;
        font-size: 16px;
      }

      .el-image__inner {
        object-fit: cover;
      }
    }
    .el-image.preview2 {
      &::after {
        content: "电子版";
      }
    }
  }
}

.smallTitle {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  // line-height: 50px;
  color: #e60012;
  opacity: 1;
  margin-top: 10px;
}

.smallTitleTwo {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  // line-height: 50px;
  color: #e60012;
  opacity: 1;
  margin-top: 6px;
}
</style>
